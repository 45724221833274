import React from 'react';
import PropTypes from 'prop-types';
// import Link from 'next/link';
import Link from '../../../components/Link';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import Logo from 'reusecore/src/elements/UI/Logo';
import Container from '../../../components/UI/Container';
import FooterWrapper, { List, ListItem } from './footer.style';
import SocialProfile from '../SocialProfile';
import LogoImage from '../../../assets/image/gojoylab/logo.png';

import { FOOTER_WIDGET } from '../../../data/gojoylab';
import { SOCIAL_PROFILES } from '../../../data/Portfolio/data';

const Footer = ({
  row,
  col,
  colOne,
  colTwo,
  titleStyle,
  logoStyle,
  textStyle,
}) => {
  return (
    <FooterWrapper>
      <Container className="footer_container">
        <Box className="row" {...row}>
          <Box {...colOne}>
            <Logo
              href="/"
              logoSrc={LogoImage}
              title="GojoyLab"
              logoStyle={logoStyle}
            />
            <Link to="mailto:services@gojoylab.com">
            <Text {...textStyle}
            content={`services@gojoylab.com`}
            className="logoText"
          />
            {/* <a className="logoText" {...textStyle}>services@gojoylab.com</a> */}
            </Link>
            
            {/* <Text content={`Copyright © ${new Date().getFullYear()} GojoyLab Technology Co., Ltd.`} {...textStyle} />
            <Text content={`All rights reserved.`} {...textStyle} /> */}

          </Box>
          {/* End of footer logo column */}
          <Box {...colTwo}>
            {FOOTER_WIDGET.map((widget, index) => (
              <Box className="col" {...col} key={`footer-widget-${index}`}>
                <Heading content={widget.title} {...titleStyle} />
                <List>
                  {widget.menuItems.map((item, index) => (
                    <ListItem key={`footer-list-item-${index}`}>
                      <Link to={item.url}>
                      <Text {...textStyle}
            content={item.text}
            className="ListItem"
          />
                        {/* <a className="ListItem">{item.text}</a> */}
                      </Link>
                    </ListItem>
                  ))}
                </List>
              </Box>
            ))}
          </Box>
          {/* End of footer List column */}
        </Box>
        <Box className="row copyRight" {...row}>
          <Text
            content={`Copyright © ${new Date().getFullYear()} GojoyLab Technology Co., Ltd.  All rights reserved.`}
            className="copyRightText"
          />
          <SocialProfile
            className="footer_social"
            items={SOCIAL_PROFILES}
            iconSize={18}
          />
        </Box>
      </Container>
    </FooterWrapper>
  );
};

// Footer style props
Footer.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  colOne: PropTypes.object,
  colTwo: PropTypes.object,
  titleStyle: PropTypes.object,
  textStyle: PropTypes.object,
  logoStyle: PropTypes.object,
};

// Footer default style
Footer.defaultProps = {
  // Footer row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // Footer col one style
  colOne: {
    width: [1, '35%', '35%', '23%'],
    mt: [0, '13px'],
    mb: ['30px', 0],
    pl: ['15px', 0],
    pr: ['15px', '15px', 0],
  },
  // Footer col two style
  colTwo: {
    width: ['100%', '65%', '65%', '77%'],
    flexBox: true,
    flexWrap: 'wrap',
  },
  // Footer col default style
  col: {
    width: ['100%', '50%', '50%', '25%'],
    pl: '15px',
    pr: '15px',
    mb: '30px',
  },
  // widget title default style
  titleStyle: {
    color: '#343d48',
    fontSize: '16px',
    fontWeight: '700',
    mb: '30px',
  },
  // Default logo size
  logoStyle: {
    width: '130px',
    mb: '15px',
  },
  // widget text default style
  textStyle: {
    color: '#0f2137',
    fontSize: '16px',
    mb: '10px',
  },
};

export default Footer;
