import styled from 'styled-components';
import FooterImage from '../../../assets/image/hosting/footer-bg.png';

const FooterWrapper = styled.footer`
  position: relative;
  background: #fff;
  overflow: hidden;
  padding: 15px 0 40px 0;

  .footer_container {
    background-repeat: no-repeat;
    background-position: center 50px;
    padding-top: 80px;
    padding-bottom: 80px;
    position: relative;
    @media (min-width: 576px) {
      background-image: url(${FooterImage});
    }
    @media (max-width: 990px) {
      padding-bottom: 40px;
    }
    @media (max-width: 767px) {
      padding-bottom: 0px;
    }
    .logoText{
      margin-left:0.5rem;
      color: rgba(52, 61, 72, 0.8);
      a{
        &:hover{
          color: #000;
          opacity: 0.85;
        }
      }
    }
    .copyRight{
      margin-top: 40px;
      margin-left: 0;
      margin-right: 0;
      width: calc(100% - 80px);
      @media(max-width: 1440px){
        margin-top: 30px;
      }
      @media(max-width: 768px){
        width: calc(100% - 20px);
        margin-top: 10px;
      }
      @media(max-width: 600px){
        margin-top: 0px;
      }
      .copyRightText{
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        color: rgba(52, 61, 72, 0.8);
        @media(max-width: 480px){
          font-size: 14px;
          margin-bottom: 10px;
        }
      }
      .footer_social{
        margin-left: auto;
        margin-top: -15px;
        @media(max-width: 600px){
          margin-left: 0;
          margin-top: 15px;
        }
        a{
          &:hover{
            color: #343d48;
            opacity: 0.85;
          }
        }
      }
    }
  }
`;

const List = styled.ul``;

const ListItem = styled.li`
  a {
    color: rgba(52, 61, 72, 0.8);
    font-size: 14px;
    line-height: 36px;
    transition: all 0.2s ease;
    &:hover,
    &:focus {
      outline: 0;
      text-decoration: none;
      color: #343d48;
    }
  }
`;

export { List, ListItem };

export default FooterWrapper;
