import React from 'react';
import PropTypes from 'prop-types';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import Button from 'reusecore/src/elements/Button';
import Input from 'reusecore/src/elements/Input';
import Container from '../../../components/UI/Container';

import NewsletterWrapper, { ContactFormWrapper } from './newsletter.style';

class Newsletter extends React.Component {
  
  constructor( props ) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      status: ""
    };
  }

  render() {
    const { status } = this.state;
    const { sectionWrapper, textArea, title, description, buttonArea, buttonStyle } = this.props;
    return (
    <Box {...sectionWrapper} as="section">
      <Container>
        <NewsletterWrapper id="getintouchsection">
          <Box {...textArea}>
            <Heading content="Subscribe to get in touch with us" {...title} />
            <Text
              content="Get an extended reality you can immersive joy."
              {...description}
            />
          </Box>
          <Box {...buttonArea}>
              {status === "SUCCESS" ? <p>Thanks!</p> : <p></p>}
              {status === "ERROR" && <p>Ooops! There was an error.</p>}
            <ContactFormWrapper>
            <form 
            onSubmit={this.submitForm}
            action="https://formspree.io/mvogrpdk"
            method="POST"
            >
              <Input
                inputType="email"
                label="Email address"
                iconPosition="right"
                isMaterial={true}
                className="email_input"
                name="email"
              />
              <Button {...buttonStyle} type="submit" title="SEND MESSAGE    " />
              </form>
            </ContactFormWrapper>
            {/* <MyForm /> */}
          </Box>
        </NewsletterWrapper>
      </Container>
    </Box>
    );
  }

  submitForm(ev) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: "SUCCESS" });
      } else {
        this.setState({ status: "ERROR" });
      }
    };
    xhr.send(data);
  }
}

// const Newsletter = ({
//   sectionWrapper,
//   textArea,
//   buttonArea,
//   buttonStyle,
//   title,
//   description,
// }) => {
//   return (
//     <Box {...sectionWrapper} as="section">
//       <Container>
//         <NewsletterWrapper id="getintouchsection">
//           <Box {...textArea}>
//             <Heading content="Subscribe to get in touch with us" {...title} />
//             <Text
//               content="Get an extended reality you can immersive joy."
//               {...description}
//             />
//           </Box>
//           <Box {...buttonArea}>
//             <ContactFormWrapper>
//               <Input
//                 inputType="email"
//                 label="Email address"
//                 iconPosition="right"
//                 isMaterial={true}
//                 className="email_input"
//               />
//               <Button {...buttonStyle} title="SEND MESSAGE    " />
//             </ContactFormWrapper>
//           </Box>
//         </NewsletterWrapper>
//       </Container>
//     </Box>
//   );
// };

Newsletter.propTypes = {
  sectionWrapper: PropTypes.object,
  textArea: PropTypes.object,
  buttonArea: PropTypes.object,
  buttonStyle: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
};

Newsletter.defaultProps = {
  sectionWrapper: {},
  formWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
  },
  textArea: {
    mb: ['40px', '40px', '40px', '0', '0'],
    pr: ['0', '0', '0', '80px', '100px'],
  },
  title: {
    fontSize: ['18px', '20px', '22px', '24px', '26px'],
    fontWeight: '500',
    color: '#fff',
    lineHeight: '1.34',
    mb: ['14px', '14px', '14px', '14px', '13px'],
    textAlign: ['center', 'center', 'center', 'left', 'left'],
    letterSpacing: '-0.025em',
  },
  description: {
    fontSize: ['14px', '14px'],
    fontWeight: '400',
    color: '#fefefe',
    lineHeight: '1.7',
    mb: 0,
    textAlign: ['center', 'center', 'center', 'left', 'left'],
  },
  buttonArea: {
    zIndex: 1,
  },
  buttonStyle: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '700',
    pl: '30px',
    pr: '30px',
    colors: 'secondaryWithBg',
    color: '#03103b',
  },
};

export default Newsletter;
